import React from "react";
import { Link } from "react-router-dom";
const PrivacyPolicy = ({setShowPrivacyPolicy, isVisible}) => {
	return(
	<>
		<div className="rightnavigation" style={{"display":isVisible}}>
			<div className="toheadnav">
				<Link to="#" className="btn btn-success downloadlink">Download</Link>
				<Link to="#" onClick={() => setShowPrivacyPolicy('none')} className="closeicons"><i className="fa fa-times" aria-hidden="true"></i></Link>
			</div>
			<h4>tradE2Simple Privacy Policy</h4>
			<p>Last updated: 01/08/2022</p>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis tortor faucibus, pharetra augue et, dapibus est. Nullam facilisis dolor purus, eget lobortis eros vulputate at. Donec in pharetra lacus, nec rhoncus metus. Nulla facilisi.
			</p>

			<h3>1.	Sed et ipsum sit amet eros</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis tortor faucibus, pharetra augue et, dapibus est. Nullam facilisis dolor purus, eget lobortis eros vulputate at. Donec in pharetra lacus, nec rhoncus metus. Nulla facilisi. Donec sit amet rhoncus magna. Cras ipsum dolor, dictum sed lorem et, cursus condimentum mi. Vestibulum placerat orci a neque molestie volutpat. Sed mauris odio, viverra ac tortor vitae, tempus lacinia arcu. In hendrerit erat eros. Sed felis nisl, rutrum eget diam quis, vestibulum lobortis metus. Ut est magna, aliquam ac eros at, imperdiet convallis libero. Donec quis lectus ac mi facilisis vestibulum. Ut vehicula nunc vitae nisl porttitor, vel imperdiet nibh ultrices. Praesent justo velit, porta eu leo a, tincidunt vestibulum turpis. Ut nec maximus arcu. Ut ultrices purus eget semper dictum.</p>

			<h3>2.	Lorem Ipsum</h3>
			<p>Sed semper luctus justo, in lobortis lectus volutpat ut. Fusce varius hendrerit purus, vel varius lectus fermentum ac. Nulla tempor, diam sed lacinia mattis, justo leo iaculis turpis, ac cursus dolor sapien ut metus. Praesent velit est, eleifend non bibendum suscipit, venenatis ac magna. Donec in laoreet mi.</p>
			<h3>3.	Lorem Ipsum</h3>
			<p>Sed semper luctus justo, in lobortis lectus volutpat ut. Fusce varius hendrerit purus, vel varius lectus fermentum ac. Nulla tempor, diam sed lacinia mattis, justo leo iaculis turpis, ac cursus dolor sapien ut metus. Praesent velit est, eleifend non bibendum suscipit, venenatis ac magna. Donec in laoreet mi. Etiam at est ornare, accumsan mi a, facilisis urna. Aliquam ac laoreet tortor. Maecenas dapibus sem quis odio semper, nec aliquam lectus faucibus. Aliquam tristique accumsan enim vitae mollis. Nunc laoreet sollicitudin odio sed suscipit. Cras sodales sit amet enim at accumsan. Nam sed varius eros, nec feugiat ex. Quisque nec gravida leo, in posuere augue. Pellentesque rhoncus odio dui, ac finibus enim ultricies a.</p>
			<ul>
				<li>Etiam at est ornare</li>
				<li>Nam sed varius eros, nec feugiat ex</li>
			</ul>
			
		</div>
	</>
	);
};
export default PrivacyPolicy;